import request from "../utils/request";

/**
 * 获取支付记录
 * @param query
 * @returns {AxiosPromise}
 */
export const getTradingRecord = (list) => {
    return request({
        url: '/sys/tradingRecord/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export const excel = (param) => {
    return request({
        url: '/sys/tradingRecord/excel',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        responseType: 'arraybuffer'
    });
};

/**
 * 删除
 * @param query
 * @returns {AxiosPromise}
 */
export const deleteTra = (id) => {
    console.log(id);
    return request({
        url: '/sys/tradingRecord/delete',
        method: 'GET',
        params: {id},
    });
};


export const selectType = () => {
    return request({
        url: '/sys/tradingRecord/selectType',
        method: 'GET',
    });
};