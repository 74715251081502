<template>
  <div style="padding: 20px">

  <!--条件搜索-->
    <div class="handle-box">
      <select-area @areaListId="getAreaListId"/>
      <el-input v-model="query.coachName" clearable placeholder="教练名称" class="handle-select mr10"></el-input>
      <el-input v-model="query.studentName" clearable placeholder="学员名称" class="handle-select mr10"></el-input>
      <el-input v-model="query.tradingNum" clearable placeholder="支付单号" class="handle-select mr10"></el-input>
      <el-select v-model="query.source" placeholder="缴费类型" style="width: 120px" class="mr10">
        <el-option v-for="(item,index) in tradingType" :key="index"
                   :label="item.dictLabel" :value="item.dictValue"></el-option>
      </el-select>
      <el-date-picker style="width: 260px" class="mr10" v-model="v1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>

      <el-button type="primary" icon="Search" style="margin-left: 10px" @click="handleSearch">搜索
      </el-button>
      <el-button type="info" @click="reset">重置</el-button>

      <el-popconfirm @confirm="exportExcel"
                     title="确定要导出吗？"
      >
        <template #reference>
          <el-button type="primary" icon="Download">导出</el-button>
        </template>
      </el-popconfirm>
    </div>

    <!--table展示数据-->
    <el-table
        height="610px"
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        :row-style="{height:0+'px'}"
        :cell-style="{padding:0+'px'}"
    >
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          prop="studentName"
          label="学员姓名">
      </el-table-column>
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          prop="areaName"
          label="区域">
      </el-table-column>
      <el-table-column
          width="150"
          show-overflow-tooltip="true"
          prop="campusName"
          label="校区">
      </el-table-column>
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          label="类型">
        <template v-slot="scope">
          <el-tag>{{scope.row.flagName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          prop="submitName"
          label="提交人">
      </el-table-column>
      <el-table-column
          width="160"
          show-overflow-tooltip="true"
          prop="collectionTime"
          label="缴费时间"
      ></el-table-column>
      <el-table-column
          width="155"
          show-overflow-tooltip="true"
          prop="sourceName"
          label="类型">
      </el-table-column>
      <el-table-column
          width="100"
          show-overflow-tooltip="true"
          prop="money"
          label="金额">
      </el-table-column>

      <el-table-column
          width="280"
          show-overflow-tooltip="true"
          prop="transaction"
          label="支付单号">
      </el-table-column>
      <el-table-column
          width="110"
          align="center"
          label="支付截图">
        <template v-slot="scope">
          <el-image
              class="code"
              :src="scope.row.paymentImg != null&&scope.row.paymentImg!=''? JSON.parse(scope.row.paymentImg)[0]:null"
              :preview-src-list="scope.row.paymentImg != null&&scope.row.paymentImg!=''?JSON.parse(scope.row.paymentImg):null">
            <template #error>
              <div class="code">
              </div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          width="160"
          show-overflow-tooltip="true"
          prop="createTime"
          label="创建时间">
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right" align="center">
        <template #default="scope">
          <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getTradingRecord, excel, deleteTra, selectType} from "@/api/tradingRecord";
import selectArea from "../../components/SelectArea";
import SelectArea from "../../components/SelectArea";
export default {
  name: "tradingRecord",
  components: {SelectArea},
  props:{
    selectArea
  },
  data() {
    return {
      tableData: [],
      pageTotal: null,
      v1: [],
      startDateAndEndDate: null,
      regionList: [],
      campusList: [],
      tradingType: [],
      query: {
        page: 1,
        pageSize: 10,
        source: '',
        coachName: '',
        areaList:[],
        startDate: '',
        entDate: '',
        tradingNum: '',
        studentName: "",
      },
    }
  },
  methods: {
    getAreaListId(val){
      this.query.areaList = val;
    },
    getData() {
      getTradingRecord(this.query).then(val => {
        console.log(val)
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
          this.source = val.data.source;
          this.coachName = val.data.coachName;
          this.tradingNum = val.data.tradingNum;
        } else {
          this.$message.error("获取表格数据失败");
        }
        console.log(val)
      })
    },
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除这条记录吗? ", {
        type: "warning"
      }).then(() => {
        deleteTra(row.id).then(res => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
            this.getData()
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    },
    /**
     * 切换页面
     */
    handlePageChange(val) {
      this.query.page = val;
      this.getData();
    },
    // 触发搜索按钮
    handleSearch() {
      if (this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      }
      this.query.pageIndex = 1
      this.getData();
    },

    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },			// 选中城市  渲染校区
    selectCity1(val) {
      this.query.campusId = null
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(JSON.stringify(this.regionList[val])).campuses
      }
    },
    // 编辑 选中城市  渲染校区
    selectCity2(val) {
      this.form.campusid = null
      this.addForm.campusid = null
      this.campusList1 = JSON.parse(JSON.stringify(this.regionList[val - 1])).campuses;
    },
    //导出
    exportExcel() {
      this.loading = true
      if (this.startDateAndEndDate) {
        this.query.startDate = this.dateFormat(this.startDateAndEndDate[0])
        this.query.endDate = this.dateFormat(this.startDateAndEndDate[1])
      }
      excel(this.query).then(res => {
        console.log(res)
        this.loading = false
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '统计表'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    //重置
    reset() {
      this.query.regionId = ""
      this.query.campusId = ""
      this.query.source = ""
      this.query.coachName = ""
      this.query.tradingNum = ""
      this.query.studentName = ""
      // 日期
      this.v1 = []
      this.query.startDate = ""
      this.query.status = ""
      this.query.endDate = ""
      this.query.pageIndex = 1
      this.getData()
    },
  },
  /**
   * 加载页面
   */
  created() {
    this.getData();
    selectType().then(res => {
      if (res.code==200){
        this.tradingType=res.data
      }else {
        this.$message.error("获取类型数据失败");
      }
    })
  },
}
</script>

<style scoped>
.handle-box {
  display: flex;
  margin-bottom: 20px;
}

.handle-select {
  width: 160px;
}

.mr10 {
  margin-right: 10px;
}

.code {
  margin-top: 8px;
  width: 80px;
  height: 40px;
  border-radius: 6px;
  background-color: #efefef;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>